import { UserGlobalAction, UserGlobalStateActions, UserState } from './types';

export const userInitialState: UserState = {
  accessToken: null,
  refreshToken: null,
  expiresOn: null,
  ctUser: null,
  shamrockUser: null,
  activeAccount: null,
  activeWarehouse: null,
  accountList: [],
  loading: true,
  isSuperUser: false,
  loggedAsSuperUser: false,
  superUserEmail: '',
  readyToLogin: false,
};

export function reduceUser(state: UserState, action: UserGlobalAction): UserState {
  switch (action.type) {
    case UserGlobalStateActions.LOGIN:
      return {
        ...state,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        expiresOn: action.payload.expiresOn,
        shamrockUser: action.payload.shamrockUser,
        ctUser: action.payload.ctUser,
        activeAccount: action.payload.activeAccount,
        activeWarehouse: action.payload.activeWarehouse,
        accountList: action.payload.accountList,
      };
    case UserGlobalStateActions.LOGOUT:
      return userInitialState;
    case UserGlobalStateActions.SET_LOADING:
      return { ...state, loading: action.payload.loading };
    case UserGlobalStateActions.SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        expiresOn: action.payload.expiresOn,
        readyToLogin: action.payload.readyToLogin || state.readyToLogin,
      };
    case UserGlobalStateActions.SET_IS_SUPER_USER:
      return { ...state, isSuperUser: action.payload.isSuperUser, superUserEmail: action.payload.superUserEmail };
    case UserGlobalStateActions.SET_LOGGED_AS_SUPER_USER:
      return { ...state, loggedAsSuperUser: action.payload.loggedAsSuperUser };

    case UserGlobalStateActions.SET_ACCOUNT_LIST:
      return {
        ...state,
        accountList: action.payload?.accountList || [],
      };
    case UserGlobalStateActions.SET_STATE_FOR_NEW_IMPERSONATION: {
      //eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { accessToken, expiresOn, refreshToken, readyToLogin, isSuperUser, ...rest } = userInitialState;
      return {
        ...state,
        ...rest,
        loading: true,
      };
    }
    default:
      return state;
  }
}
