import { useToast } from '@chakra-ui/react';
import { Cart } from '@Types/cart/Cart';
import { LineItem } from '@Types/cart/LineItem';
import { Money } from '@Types/product/Money';
import { Variant } from '@Types/product/Variant';
import { useGlobal } from 'components/globalProvider';
import {
  CART_HAS_BEEN_SUBMITTED_ID,
  GENERIC_TOAST_ERROR_ID,
  TOAST_ICON,
  UPDATE_LINE_ITEM_ERROR_ID,
} from 'composable/components/general';
import { AuthErrorKeys } from 'helpers/constants/auth';
import { useFormat } from 'helpers/hooks/useFormat';
import { UpdateCartType } from 'helpers/types/cart-types';
import { LOGOUT_CALLERS } from 'hooks/global/use_privateUserGlobal/constants';

interface CartHelpersArgs {
  showToastMessages?: boolean;
}

const TOAST_DELAY = 500;

export const useCartHelpers = ({ showToastMessages = false }: CartHelpersArgs) => {
  const intl = useFormat({ name: 'common' });
  const toast = useToast();
  const { cart, shouldAddToQueue, addItem, updateItem, removeItem } = useGlobal().useCartGlobal;
  const { logout } = useGlobal().useUserGlobal;

  // Add Cart Item
  async function addItemToCart(
    variant: Variant,
    quantity: number,
    priceValues: Money,
    productName: string,
  ): Promise<UpdateCartType> {
    try {
      // avoid something causing adding 0 items to cart
      const checkedQuantity = quantity == 0 ? 1 : quantity;

      const item = [{ variant: variant, quantity: checkedQuantity, price: priceValues }];
      const res = await addItem(item, cart?.cartId);
      if (shouldAddToQueue) {
        return;
      }

      const toastMessage = {
        successMsg: intl.formatMessage({ id: 'cart.item.add.success', values: { name: productName } }),
        errorMsg: intl.formatMessage({ id: 'cart.item.add.error', values: { name: productName } }),
      };

      const operationTest = { success: !!res?.cartId };

      if (showToastMessages) {
        handleToastMessage(operationTest, { ...toastMessage });
      } else if (operationTest?.success === false) {
        // In case of error always shows the toast message
        handleToastMessage(operationTest, { ...toastMessage });
      }

      return { success: operationTest?.success, itemId: variant.sku, quantity: checkedQuantity, nextCart: res };
    } catch (err) {
      console.error('Error API call: addItemToCart', err);
      setTimeout(() => {
        if (!toast.isActive(CART_HAS_BEEN_SUBMITTED_ID)) {
          toast({
            duration: 5000,
            status: 'error',
            title: intl.formatMessage({ id: 'cart.item.add.error', values: { name: productName } }),
            id: GENERIC_TOAST_ERROR_ID,
            icon: TOAST_ICON.error,
          });
        }
      }, TOAST_DELAY);
    }

    return { success: false };
  }

  // Update Cart Item Quantity
  async function updateLineItemQuantity(item: LineItem, quantity: number, priceValues: Money): Promise<UpdateCartType> {
    const itemId = item.lineItemId;
    try {
      const res =
        quantity === 0
          ? await removeItem(item, cart.cartId)
          : await updateItem(item, quantity, priceValues, cart.cartId);
      if (shouldAddToQueue) {
        return;
      }

      const operationTest = { success: !!res?.cartId };

      const toastMessage =
        quantity === 0
          ? {
              successMsg: intl.formatMessage({ id: 'cart.item.remove.success' }),
              errorMsg: intl.formatMessage({ id: 'cart.item.remove.error' }),
            }
          : {
              successMsg: intl.formatMessage({ id: 'cart.item.update.success' }),
              errorMsg: intl.formatMessage({ id: 'cart.item.update.error' }),
              errorToastId: UPDATE_LINE_ITEM_ERROR_ID,
            };

      if (showToastMessages) {
        handleToastMessage(operationTest, { ...toastMessage });
      }
      if (res?.statusCode === 401) {
        // IF TOKEN EXPIRES REDIRECT TO SIGN IN AGAIN
        await logout(false, AuthErrorKeys.SessionExpired, LOGOUT_CALLERS.SOFT_LOGOUT_FROM_CART_HELPERS_UPDATE);
      }
      if (operationTest?.success === false) {
        // In case of error always shows the toast message
        handleToastMessage(operationTest, { ...toastMessage });
      }
      return {
        success: operationTest?.success,
        quantity: quantity,
        itemId: itemId,
        nextCart: res,
        prevCart: cart,
      };
    } catch (err) {
      console.error('Error API call: updateLineItemQuantity', err);
      setTimeout(() => {
        if (!toast.isActive(UPDATE_LINE_ITEM_ERROR_ID) && !toast.isActive(CART_HAS_BEEN_SUBMITTED_ID)) {
          toast({
            duration: 5000,
            status: 'error',
            title: intl.formatMessage({ id: 'cart.item.update.error' }),
            id: UPDATE_LINE_ITEM_ERROR_ID,
            icon: TOAST_ICON.error,
          });
        }
      }, TOAST_DELAY);
    }

    return { success: false };
  }

  // Search Cart Line Items for Item. Update quantity if found, else add to cart
  async function addOrUpdateLineItemToCart(
    variant: Variant,
    quantity: number,
    priceValues: Money,
    productName: string,
  ): Promise<UpdateCartType> {
    let matchFound = false;
    let res = { success: false } as UpdateCartType;

    const prevQuantity = getCartQuantityForItem(cart, variant.sku);

    try {
      for (const item of cart?.lineItems) {
        if (item.variant.sku === variant.sku) {
          // Perform update quantity action
          res = await updateLineItemQuantity(item, quantity, priceValues);
          matchFound = true;
          break;
        }
      }

      // No match found, add to cart
      if (!matchFound) {
        res = await addItemToCart(variant, quantity, priceValues, productName);
        if (res?.success === false && res?.nextCart?.statusCode === 401) {
          // If token expires, redirect to unauthorized error page
          await logout(false, AuthErrorKeys.SessionExpired, LOGOUT_CALLERS.SOFT_LOGOUT_FROM_CART_HELPERS_ADD_OR_UPDATE);
        }
      }
    } catch (error) {
      console.error('Error API call: addOrUpdateLineItemToCart', error);
      setTimeout(() => {
        if (!toast.isActive(UPDATE_LINE_ITEM_ERROR_ID) && !toast.isActive(CART_HAS_BEEN_SUBMITTED_ID)) {
          toast({
            duration: 5000,
            status: 'error',
            title: intl.formatMessage({ id: 'cart.item.update.error' }),
            id: UPDATE_LINE_ITEM_ERROR_ID,
            icon: TOAST_ICON.error,
          });
        }
      }, TOAST_DELAY);
    }

    return {
      success: res?.success,
      quantity: res?.quantity,
      prevQuantity: prevQuantity,
      itemId: res?.itemId,
      nextCart: res?.nextCart,
      prevCart: res?.prevCart,
    };
  }

  // Get Cart Quantity for Item
  function getCartQuantityForItem(cart: Cart, sku: string) {
    const item = cart?.lineItems?.find((item) => item.variant.sku === sku);

    return item ? item.count : 0;
  }

  // Toast Message Handler
  function handleToastMessage(
    res: { success: boolean },
    messages: { successMsg: string; errorMsg: string; errorToastId?: string },
  ) {
    const { successMsg, errorMsg, errorToastId = GENERIC_TOAST_ERROR_ID } = messages;

    if (res?.success) {
      toast({
        duration: 5000,
        status: 'success',
        title: successMsg,
        icon: TOAST_ICON.success,
      });
    } else {
      setTimeout(() => {
        if (!toast.isActive(errorToastId) && !toast.isActive(CART_HAS_BEEN_SUBMITTED_ID)) {
          toast({
            duration: 5000,
            status: 'error',
            title: errorMsg,
            id: errorToastId,
            icon: TOAST_ICON.error,
          });
        }
      }, TOAST_DELAY);
    }
  }

  return {
    addItemToCart,
    addOrUpdateLineItemToCart,
    getCartQuantityForItem,
  };
};
