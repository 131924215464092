import { Cart } from '@Types/cart/Cart';
import { LineItem } from '@Types/cart/LineItem';
import { Order } from '@Types/cart/Order';
import { Product } from '@Types/product/Product';
import { ExtraProductData } from '@Types/shamrockApi/Product';
import { CURRENT_ALGOLIA_LOCALE } from 'composable/components/plp/constants';
import { getValueByLocale } from 'composable/components/plp/utils';
import { DEFAULT_CURRENCY } from 'helpers/constants/eventTracking';
import { UpdateCartType } from 'helpers/types/cart-types';
import { getCategoriesFromProduct, preciseMultiply } from 'helpers/utils/eventTracking';
import { cartItemsAnalyticsManager, viewProductAnalytics } from 'helpers/utils/productsAnalyticsManager';
import { analyticsClient } from './client';
import {
  GenericAddOGEvent,
  GenericCartDeleted,
  GenericItem,
  GenericNewCartRequested,
  GenericParSetup,
  GenericPrintBackDownloadEvent,
} from './generic/types';
import { ProductAttributeKeys } from 'composable/components/types';

/**
 * Find an LineItem by line item id
 * @param cartItem
 * @param id
 */
const findCartItemByLineItemId = (cartItem: LineItem, id: string) => {
  return cartItem.lineItemId === id;
};

/**
 * Find an LineItem by line item sku
 * @param cartItem
 * @param id
 */
const findCartItemBySku = (cartItem: LineItem, sku: string) => {
  return cartItem.variant.sku === sku;
};

const formatPrice = (price: number = 0) => {
  return price / 100;
};

const removeCartItemAnalyticsData = (listItem) => {
  const { setCartData } = cartItemsAnalyticsManager();
  const cartAnalyticsData = setCartData(listItem, 'remove');
  return cartAnalyticsData;
};

const getCartAnalyticsData = () => {
  const { getCartData } = cartItemsAnalyticsManager();
  const cartAnalyticsData = getCartData();
  return cartAnalyticsData;
};

const mapAnalyticsDataToCart = (cart) => {
  const cartAnalyticsData = getCartAnalyticsData();
  const mergedArray = cart?.lineItems?.map((cartItem) => {
    const matchedLineItem = cartAnalyticsData?.lineItems.find(
      (itemAnalytics) => cartItem?.productSlug === itemAnalytics.productId,
    );

    if (matchedLineItem) {
      return {
        ...cartItem,
        index: matchedLineItem?.index,
        categories: matchedLineItem?.categories,
        list_id: matchedLineItem?.itemListId,
        list_name: matchedLineItem?.itemListName,
      };
    }

    // If there's no match, just return the original object
    return cartItem;
  });
  return mergedArray;
};

/**
 * Tracks a cartItem update
 */
export const analyticsTrackUpdateCart = (params: {
  lineItemId: string;
  quantity?: number;
  prevCart?: Partial<Cart>;
  nextCart?: Partial<Cart>;
  fromPAR: boolean;
}) => {
  const { prevCart, nextCart, lineItemId } = params;
  const item = prevCart?.lineItems?.find((el) => findCartItemByLineItemId(el, lineItemId));
  const nextQty = params?.quantity ?? 0;
  const prevQty = item?.count ?? 0;
  const quantity = Math.abs(nextQty - prevQty);

  if (nextQty > prevQty) {
    analyticsTrackAddToCart({
      ...params,
      sku: item?.variant.sku!,
      quantity,
    });
  } else {
    analyticsTrackRemoveFromCart({ lineItemId, prevCart, quantity, nextCart });
  }
};

/**
 * Tracks the add to cart event
 */
export const analyticsTrackAddToCart = (params: {
  sku: string;
  quantity?: number;
  nextCart?: Partial<Cart>;
  fromPAR: boolean;
}) => {
  const { nextCart, sku, quantity: itemQuantityAdded, fromPAR } = params;
  const item = nextCart?.lineItems?.find((el) => findCartItemBySku(el, sku));
  const quantity = itemQuantityAdded ?? item.count;

  if (!item) {
    return;
  }

  const cartAnalyticsData = getCartAnalyticsData();
  const itemAnalyticsData = cartAnalyticsData?.lineItems?.find((el) => el.productId === sku);

  const brandAttribute = item.variant?.attributes[ProductAttributeKeys.BrandLongDescription];

  analyticsClient.track({
    name: 'add_to_cart',
    params: {
      cart_id: nextCart?.cartId ?? '',
      currency: item.price?.currencyCode ?? '',
      value: preciseMultiply(formatPrice(item.price?.centAmount), quantity),
      items: [
        {
          id: item.variant.sku ?? '',
          name: item.name ?? '',
          sku: item.variant.sku,
          price: formatPrice(item.price?.centAmount),
          quantity,
          source: window.location.pathname,
          image_url: item.variant?.images?.[0]?.url,
          index: itemAnalyticsData?.index,
          categories: itemAnalyticsData?.categories,
          list_id: itemAnalyticsData?.itemListId,
          list_name: itemAnalyticsData?.itemListName,
          brand:
            typeof brandAttribute === 'string'
              ? brandAttribute
              : getValueByLocale(
                  item.variant.attributes,
                  ProductAttributeKeys.BrandLongDescription,
                  CURRENT_ALGOLIA_LOCALE,
                ),
        },
      ],
      fromPAR,
    },
  });
};

/**
 * Tracks the search event
 */
export const analyticsTrackSearch = (params: { algoliaState: any }) => {
  const { algoliaState } = params;
  const { page, refinementList, toggle, sortBy, query, configure } = algoliaState;
  const { query: configureQuery } = configure || {};

  analyticsClient.track({
    name: 'search',
    params: {
      search_term: query ?? configureQuery ?? '',
      page,
      refinement_list: refinementList,
      toggle,
      sort_by: sortBy,
    },
  });
};

/**
 * Tracks the remove from cart event
 */
export const analyticsTrackRemoveFromCart = (params: {
  lineItemId: string;
  prevCart?: Partial<Cart>;
  nextCart?: Partial<Cart>;
  quantity?: number;
}) => {
  const { prevCart, nextCart, lineItemId, quantity: itemQuantityRemoved } = params;

  const item = prevCart?.lineItems?.find((el) => findCartItemByLineItemId(el, lineItemId));
  const quantity = itemQuantityRemoved ?? item?.count;

  if (!item) {
    return;
  }

  const cartAnalyticsData = getCartAnalyticsData();
  const itemAnalyticsData = cartAnalyticsData?.lineItems?.find((el) => el.productId === item?.productSlug);

  // remove item from current-cart local storage if the item does not exists in the cart
  const itemExistsInCart = nextCart?.lineItems?.find((el) => findCartItemBySku(el, item.variant.sku));
  if (!itemExistsInCart) {
    removeCartItemAnalyticsData(itemAnalyticsData);
  }

  analyticsClient.track({
    name: 'remove_from_cart',
    params: {
      cart_id: prevCart?.cartId ?? '',
      value: preciseMultiply(formatPrice(item.price?.centAmount), quantity),
      currency: item.totalPrice?.currencyCode ?? '',
      items: [
        {
          id: item.variant.sku ?? '',
          name: item.name ?? '',
          price: formatPrice(item.price?.centAmount),
          quantity,
          source: window.location.pathname,
          image_url: item.variant?.images?.[0]?.url,
          index: itemAnalyticsData?.index,
          categories: itemAnalyticsData?.categories,
          list_id: itemAnalyticsData?.itemListId,
          list_name: itemAnalyticsData?.itemListName,
          brand: item?.variant?.attributes?.['shamrock-standard_brand_long_description'],
        },
      ],
    },
  });
};

export const analyticsTrackViewCart = (params: { cart?: Partial<Cart> }) => {
  const { cart } = params;
  if (!cart) {
    return;
  }

  const cartLineItemsAnalyticData = mapAnalyticsDataToCart(cart);

  analyticsClient.track({
    name: 'view_cart',
    params: {
      cart_id: cart.cartId ?? '',
      value: formatPrice(cart?.sum?.centAmount),
      currency: cart?.sum?.currencyCode ?? '',
      items:
        cartLineItemsAnalyticData?.map((item) => ({
          id: item.variant.sku ?? '',
          name: item.name ?? '',
          brand: item.variant?.attributes?.['shamrock-standard_brand_long_description'] ?? '',
          price: formatPrice(item.price?.centAmount),
          quantity: item.count ?? 0,
          index: item?.index,
          categories: item?.categories,
          list_id: item?.list_id,
          list_name: item?.list_name,
        })) ?? [],
    },
  });
};

export const analyticsTrackBeginCheckout = (params: { cart?: Partial<Cart> }) => {
  const { cart } = params;
  if (!cart) {
    return;
  }

  const cartLineItemsAnalyticData = mapAnalyticsDataToCart(cart);

  analyticsClient.track({
    name: 'begin_checkout',
    params: {
      cart_id: cart.cartId ?? '',
      value: formatPrice(cart?.sum?.centAmount),
      currency: cart.sum?.currencyCode ?? '',
      coupon: cart.discountCodes?.[0]?.code,
      items:
        cartLineItemsAnalyticData?.map((el) => ({
          id: el.variant.sku ?? '',
          name: el.name ?? '',
          price: formatPrice(el.price?.centAmount),
          quantity: el.count ?? 0,
          brand: el.variant?.attributes?.['shamrock-standard_brand_long_description'] ?? '',
          index: el?.index,
          categories: el?.categories,
          list_id: el?.list_id,
          list_name: el?.list_name,
        })) ?? [],
    },
  });
};

export const analyticsTrackAddShippingInfo = (params: { cart?: Partial<Cart>; shippingMethodId?: string }) => {
  const { cart, shippingMethodId } = params;
  if (!cart) {
    return;
  }

  const shippingMethodName = cart.availableShippingMethods.find(
    (shippingMethod) => shippingMethod.shippingMethodId === shippingMethodId,
  )?.name;

  analyticsClient.track({
    name: 'add_shipping_info',
    params: {
      value: formatPrice(cart?.sum?.centAmount),
      currency: cart.sum?.currencyCode ?? '',
      coupon: cart.discountCodes?.[0]?.code,
      shipping_tier: shippingMethodName,
      items:
        cart.lineItems?.map((el) => {
          return {
            item_id: el.productId ?? '',
            item_name: el.name ?? '',
            currency: el.totalPrice?.currencyCode ?? '',
            price: formatPrice(el.totalPrice?.centAmount),
            quantity: el.count ?? 0,
          };
        }) ?? [],
    },
  });
};

export const analyticsTrackAddPaymentInfo = (params: { cart?: Partial<Cart>; paymentType?: string }) => {
  const { cart, paymentType } = params;
  if (!cart) {
    return;
  }

  analyticsClient.track({
    name: 'add_payment_info',
    params: {
      value: formatPrice(cart?.sum?.centAmount),
      currency: cart?.sum?.currencyCode ?? '',
      coupon: cart.discountCodes?.[0]?.code,
      payment_type: paymentType ?? '',
      items:
        cart.lineItems?.map((el) => {
          return {
            item_id: el.productId ?? '',
            item_name: el.name ?? '',
            currency: el.totalPrice?.currencyCode ?? '',
            price: formatPrice(el.totalPrice?.centAmount),
            quantity: el.count ?? 0,
          };
        }) ?? [],
    },
  });
};

export const analyticsTrackPurchase = (params: { order?: Partial<Order> }) => {
  const { order } = params;
  if (!order) {
    return;
  }

  const cartLineItemsAnalyticData = mapAnalyticsDataToCart(order);

  analyticsClient.track({
    name: 'purchase',
    params: {
      cart_id: order.cartId,
      currency: order?.sum?.currencyCode ?? '',
      transaction_id: order.orderNumber,
      value: formatPrice(order?.sum?.centAmount),
      coupon: order.discountCodes?.[0]?.code,
      shipping: formatPrice(order.shippingInfo?.price?.centAmount),
      tax: formatPrice(
        order.taxed?.taxPortions.reduce((totalTax, currentTax) => {
          return totalTax + currentTax.amount.centAmount;
        }, 0),
      ),
      delivery_date: order?.custom?.fields?.requestDate,
      delivery_type: order?.shippingInfo?.shippingMethodName,
      shipping_address: order.shippingAddress,
      billing_address: order.billingAddress,
      items:
        cartLineItemsAnalyticData?.map((el) => {
          const brandAttribute = el?.variant?.attributes?.[ProductAttributeKeys.BrandLongDescription] || null;

          return {
            id: el.variant.sku ?? '',
            name: el.name ?? '',
            price: formatPrice(el.price?.centAmount),
            quantity: el.count ?? 0,
            index: el?.index,
            categories: el?.categories,
            list_id: el?.list_id,
            list_name: el?.list_name,
            brand:
              typeof brandAttribute === 'string'
                ? brandAttribute
                : getValueByLocale(
                    el.variant.attributes,
                    'shamrock-standard_brand_long_description',
                    CURRENT_ALGOLIA_LOCALE,
                  ),
          };
        }) ?? [],
    },
  });
};

export const analyticsTrackViewItem = (params: {
  product?: Partial<Product>;
  extraProductData?: Partial<ExtraProductData>;
  cart?: Cart;
  itemListId?: string;
  itemListName?: string;
}) => {
  const { product, extraProductData, cart, itemListId, itemListName } = params;
  if (!product || !extraProductData) {
    return;
  }

  const firstVariant = product.variants?.[0];
  const sku = firstVariant?.sku;
  const brand = firstVariant?.attributes['shamrock-standard_brand_long_description'];
  const price = extraProductData.pricingInfo?.data?.discountedPrice;

  const item = cart?.lineItems?.find((el) => findCartItemBySku(el, sku));
  const quantity = item?.count ?? 0;

  const { getViewProduct } = viewProductAnalytics();
  const viewProductData = getViewProduct();
  const index = viewProductData.productId === sku ? viewProductData.index : undefined;
  analyticsClient.track({
    name: 'view_item',
    params: {
      value: preciseMultiply(formatPrice(price), quantity),
      currency: DEFAULT_CURRENCY,
      items: [
        {
          id: sku ?? '',
          name: product?.name ?? '',
          price: formatPrice(price),
          quantity,
          brand: brand ?? '',
          categories: getCategoriesFromProduct(product),
          index,
          list_id: itemListId,
          list_name: itemListName,
        },
      ],
    },
  });
};

export const analyticsTrackSignUp = ({ method }: { method?: string }) => {
  analyticsClient.track({
    name: 'sign_up',
    params: {
      method,
    },
  });
};

export const analyticsTrackLogin = ({ method, customerNumber }: { method?: string; customerNumber?: string }) => {
  analyticsClient.track({
    name: 'login',
    params: {
      method,
      customerNumber,
    },
  });
};

export const analyticsTrackViewItemList = ({
  itemListId,
  itemListName,
  items,
}: {
  itemListId: string;
  itemListName: string;
  items: GenericItem[];
}) => {
  analyticsClient.track({
    name: 'view_item_list',
    params: {
      list_id: itemListId,
      list_name: itemListName,
      items,
    },
  });
};

export const analyticsTrackSelectItem = ({
  itemListId,
  itemListName,
  item,
}: {
  itemListId: string;
  itemListName: string;
  item: GenericItem;
}) => {
  analyticsClient.track({
    name: 'select_item',
    params: {
      list_id: itemListId,
      list_name: itemListName,
      items: [item],
    },
  });
};

export const analyticsTrackAddToOrderGuide = (params: GenericAddOGEvent['params']) => {
  analyticsClient.track({
    name: 'add_to_orderguide',
    params,
  });
};

export const analyticsTrackCart = (cartResponse: UpdateCartType & { fromPAR: boolean }) => {
  if (!cartResponse?.itemId) {
    return;
  }

  if (cartResponse?.quantity === 1 && !!cartResponse?.nextCart) {
    analyticsTrackAddToCart({
      sku: cartResponse?.itemId,
      quantity: cartResponse?.quantity,
      nextCart: cartResponse?.nextCart,
      fromPAR: cartResponse.fromPAR,
    });
  } else if (cartResponse?.quantity > 1 && !!cartResponse?.nextCart && !!cartResponse?.prevCart) {
    analyticsTrackUpdateCart({
      lineItemId: cartResponse?.itemId,
      quantity: cartResponse?.quantity,
      nextCart: cartResponse?.nextCart,
      prevCart: cartResponse?.prevCart,
      fromPAR: cartResponse.fromPAR,
    });
  } else if (cartResponse?.quantity === 0 && !!cartResponse?.nextCart && !!cartResponse?.prevCart) {
    analyticsTrackRemoveFromCart({
      lineItemId: cartResponse?.itemId,
      nextCart: cartResponse?.nextCart,
      prevCart: cartResponse?.prevCart,
    });
  }
};

export const analyticsTrackPrintBackDownload = (params: GenericPrintBackDownloadEvent['params']) => {
  analyticsClient.track({
    name: 'printback_download',
    params,
  });
};

export const analyticsTrackNewCartRequested = (params: GenericNewCartRequested['params']) => {
  analyticsClient.track({
    name: 'new_cart_requested',
    params,
  });
};

export const analyticsTrackCartDeleted = (params: GenericCartDeleted['params']) => {
  analyticsClient.track({
    name: 'cart_deleted',
    params,
  });
};

export const analyticsTrackParSetup = (params: GenericParSetup['params']) => {
  analyticsClient.track({ name: 'par_setup', params });
};
