import { HStack, Input, Text, VStack, Flex } from '@chakra-ui/react';
import { useGlobal } from 'components/globalProvider';
import { useUserHelpers } from 'composable/helpers/hooks/useUserHelpers';
import { useFormat } from 'helpers/hooks/useFormat';
import { BillToProps } from '../types';

const MAX_LENGTH = 15;

export const BillTo = ({ successPage = false }: BillToProps) => {
  const { formatMessage } = useFormat({ name: 'common' });
  const checkoutContext = useGlobal().useCheckoutGlobal;
  const { getBillingAddress, getCompanyName, getUnitStore } = useUserHelpers();

  const companyName = getCompanyName();
  const billing = getBillingAddress();
  const unitStore = getUnitStore();
  const {
    state: { shamrockUser },
  } = useGlobal().useUserGlobal;
  const { updateEditedOrder, isEditingOrder, editCart } = useGlobal().useEditOrderGlobal;
  const permissions = shamrockUser?.user?.permissions?.data ?? [];
  const handlePONumberChange = (value: string) => {
    checkoutContext.setPONumber(value);
    if (isEditingOrder) {
      updateEditedOrder({ ...editCart, purchaseOrderNumber: value });
      return;
    }
  };

  return (
    <VStack align="unset" spacing={1}>
      {companyName && (
        <Text color="neutral.900" fontWeight="bold" lineHeight="120%">
          {companyName}
        </Text>
      )}

      <Flex color="neutral.600" fontSize="sm" lineHeight="150%" flexDirection="column" gap={1}>
        {billing && (
          <>
            <Text as="p" textTransform="capitalize">
              {billing?.streetName?.toLowerCase()}
            </Text>
            <Text as="p" textTransform="capitalize">
              {billing?.city?.toLowerCase()}, {billing?.state} {billing?.postalCode}
            </Text>
          </>
        )}
      </Flex>
      {permissions.includes('CanViewBillingInformation') && (
        <HStack
          align="center"
          borderBottomWidth={1}
          borderColor="neutral.100"
          borderTopWidth={1}
          justify="space-between"
          mt={2}
          py={1}
        >
          <Text color="neutral.600" fontSize="sm" fontWeight="bold" lineHeight="150%">
            {formatMessage({ id: 'orderSuccess.panel.billTo.paymentTerm' })}
          </Text>
          <Text color="neutral.600" fontSize="sm" lineHeight="150%">
            {unitStore?.PaymentTerms}
          </Text>
        </HStack>
      )}
      {!successPage && (
        <>
          <Text color="neutral.900" fontSize="sm" fontWeight="bold" lineHeight="150%" mt={4}>
            Purchase Order (optional)
          </Text>
          <Input
            bg="neutral.50"
            borderColor="neutral.300"
            fontSize="sm"
            h={8}
            onChange={(e) => handlePONumberChange(e.target.value)}
            mt={1}
            maxLength={MAX_LENGTH}
            placeholder="Example: PO 123456789"
            _placeholder={{ color: 'neutral.600' }}
            value={isEditingOrder ? editCart.purchaseOrderNumber : checkoutContext.state?.poNumber}
          />
        </>
      )}
    </VStack>
  );
};

BillTo.displayName = 'BillTo';
