export const FRONTASTIC_COOKIE = 'frontastic-session';

export const LOGOUT_CALLERS = {
  REFRESH_TOKEN: 'REFRESH_TOKEN',
  REDIRECT_FROM_SO: 'REDIRECT_FROM_SO',

  HARD_LOGOUT_FROM_PUBLIC_HEADER: 'HARD_LOGOUT_FROM_PUBLIC_HEADER',
  HARD_LOGOUT_FROM_BUTTONS_LINKS: 'HARD_LOGOUT_FROM_BUTTONS_LINKS',
  HARD_LOGOUT_FROM_LEFT_SIDE: 'HARD_OGOUT_FROM_LEFT_SIDE',
  HARD_LOGOUT_FROM_FOOTER: 'HARD_LOGOUT_FROM_FOOTER',
  HARD_LOGOUT_FROM_MEGA_DRAWER: 'HARD_LOGOUT_FROM_MEGA_DRAWER',

  SOFT_LOGOUT_FROM_ACCOUNTS_HEADER: 'SOFT_LOGOUT_FROM_ACCOUNTS_HEADER',
  SOFT_LOGOUT_FROM_ORDER_DETAIL: 'SOFT_LOGOUT_FROM_ORDER_DETAIL',
  SOFT_LOGOUT_FROM_ORDER_LIST: 'SOFT_LOGOUT_FROM_ORDER_LIST',
  SOFT_LOGOUT_FROM_ERRORS: 'SOFT_LOGOUT_FROM_ERRORS',
  SOFT_LOGOUT_FROM_HANDLE_SHAMROCK_ERROR: 'SOFT_LOGOUT_FROM_HANDLE_SHAMROCK_ERROR',
  SOFT_LOGOUT_FROM_ALL_ACCOUNT: 'SOFT_LOGOUT_FROM_ALL_ACCOUNT',
  SOFT_LOGOUT_FROM_CART_HELPERS_UPDATE: 'SOFT_LOGOUT_FROM_CART_HELPERS_UPDATE',
  SOFT_LOGOUT_FROM_CART_HELPERS_ADD_OR_UPDATE: 'SOFT_LOGOUT_FROM_CART_HELPERS_ADD_OR_UPDATE',
  SOFT_LOGOUT_FROM_PRODUCT_EXTRA_DATA: 'SOFT_LOGOUT_FROM_PRODUCT_EXTRA_DATA',

  ERROR_ON_SET_ACCOUNT: 'ERROR_ON_SET_ACCOUNT',
  ERROR_ON_SET_ACCOUNT_PARSING_ERROR: 'ERROR_ON_SET_ACCOUNT_PARSING_ERROR',
  ERROR_ON_SET_ACCOUNT_NO_ERROR_MESSAGE: 'ERROR_ON_SET_ACCOUNT_NO_ERROR_MESSAGE',
  ERROR_NO_VALID_ACCESS_TOKEN_AND_NO_CODE: 'ERROR_NO_VALID_ACCESS_TOKEN_AND_NO_CODE',
  ERROR_FAILED_TO_GET_TOKENS: 'ERROR_FAILED_TO_GET_TOKENS',
  ERROR_SESSION_EXPIRED: 'ERROR_SESSION_EXPIRED',
  ERROR_UNABLE_TO_LOGIN: 'ERROR_UNABLE_TO_LOGIN',
  ERROR_ON_REFRESH_TOKEN: 'ERROR_ON_REFRESH_TOKEN',
  ERROR_ON_API_CALL: 'ERROR_ON_API_CALL',
  ERROR_ON_PRODUCT_EXTRA_DATA: 'ERROR_ON_PRODUCT_EXTRA_DATA',
};

export const ADDITIONAL_AZURE_URL_PARAMS = 'state';
export const MAX_REFRESH_ATTEMPT = 2;
