import { Reducer, useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { useToast } from '@chakra-ui/react';
import { Address } from '@Types/account/Address';
import { Cart } from '@Types/cart/Cart';
import { LineItem } from '@Types/cart/LineItem';
import { Order } from '@Types/cart/Order';
import { OrderDetailLineItem } from '@Types/shamrockApi/Order';
import { ExtraProductData } from '@Types/shamrockApi/Product';
import { DeliveryOptions } from 'composable/components/checkout/types';
import { DEFAULT_SHIPPING_METHOD, KEY_A_LA_CARTE, KEY_PICK_UP } from 'composable/components/checkout/utils/constants';
import { FEATURE_FLAGS, GENERIC_TOAST_ERROR_ID, TOAST_ICON } from 'composable/components/general';
import { DraftCartValues } from 'composable/components/mini-cart/helpers';
import { CHECKOUT_PRIMARY_ORDER, CHECKOUT_SECONDARY_ORDER, IS_SPLIT_ORDER } from 'composable/helpers/constants';
import { DeliveryDatesProps, useLocalStorage, useSplitOrder, useUserHelpers } from 'composable/helpers/hooks';
import { hasCreditOnHoldInfo } from 'composable/helpers/utils/user-utils';
import { isBefore } from 'date-fns';
import { getPayloadByWarehouses } from 'helpers/components/accountsOverviewHelper';
import { DEFAULT_CURRENCY } from 'helpers/constants/eventTracking';
import routes from 'helpers/constants/routes';
import { useFormat } from 'helpers/hooks';
import { usePurchaseAlgoliaTracking } from 'helpers/hooks/usePurchaseAlgoliaTracking';
import { DeliveryWindow, FormattedDeliveryOption } from 'helpers/services/shamrock';
import { formatDeliveryOptionsEligibilitiesResponse } from 'helpers/utils';
import { getProductExtraData } from 'helpers/utils/eventTracking';
import { deliveryOptions, MAX_DELIVERY_DATES } from 'helpers/utils/fetchers';
import { every, values } from 'lodash';
import delay from 'lodash/delay';
import { calculateLatestDeliveryDate } from 'utils/checkout/calculateLatestDeliveryDate';
import { mountUpdateOrderPayload } from 'utils/orderEdit/mountUpdateOrderPayload';
import { getCart } from 'frontastic/actions/cart';
import { getDeliveryOptionsEligibilities, updateCustomerOrderDetail } from 'frontastic/actions/shamrockApi';
import { MarketingMessage } from 'frontastic/tastics/composable/checkout';
import { sleep } from 'frontastic/tastics/composable/edit-order/utils';
import { checkoutGlobalReducer } from './checkout-reducer';
import { A_LA_CARTE, BASE_URL, WILL_CALL } from './constants';
import { INITIAL_STATE, scrollToElementById, shouldCallEligibilityEndpoint } from './helpers';
import {
  CheckoutGlobalActions,
  CheckoutGlobalType,
  PersistedDeliveryWindows,
  PersistedOrder,
  ProductsForDeliveryWindow,
  ShoppingCategoriesOpenProps,
  UseCheckoutGlobalReducerActions,
  UsePrivateCheckoutGlobalType,
  DeliveryWindows,
} from './types';
import { CartGlobalStateActions, CheckoutCart, CheckoutResponse } from '../use_privateCartGlobal/types';
import { actionCheckout, actionSplitCart } from '../use_privateCartGlobal/utils';

/* eslint-disable react-hooks/rules-of-hooks */
export function use_privateCheckoutGlobal({
  userData,
  isPrintBack,
  softCutoffs,
  cartActions,
  editOrder,
}: UsePrivateCheckoutGlobalType) {
  const {
    state: { loading: isLoadingUserData, activeWarehouse, accessToken, activeAccount, accountList },
    logout,
  } = userData;
  const { isEditingOrder, editCart, toggleOrderUpdatingState, disableOrderEditing, updateSplitOrder } = editOrder;
  const [state, dispatch] = useReducer<Reducer<CheckoutGlobalType, CheckoutGlobalActions>>(
    checkoutGlobalReducer,
    INITIAL_STATE,
  );
  const toast = useToast();
  const { formatMessage } = useFormat({ name: 'common' });
  const router = useRouter();
  const { asPath } = router;

  const persistedGroups = useRef<any[] | null>(null);
  const persistedLineItems = useRef<any | null>(null);
  const persistedOrderTotal = useRef<number | null>(null);
  const persistedDeliveryWindows = useRef<PersistedDeliveryWindows | null>(null);
  const _persistedIsSplitOrder = useRef<boolean>(false);
  const [extraProductData, setExtraProductData] = useState<ExtraProductData[]>([]);
  //eslint-disable-next-line
  const [_, setCutOffDeliveryDates] = useState<DeliveryDatesProps | null>(null);
  const [geocodeDeliveryDate, setGeocodeDeliveryDate] = useState<string | null>(null);
  const [radioButtonStates, setRadioButtonStates] = useState<DeliveryOptions>({
    'alc-shipping-method': false,
    'willcall-shipping-method': false,
  });

  // Checkbox for acknowledging the order information
  const setIsAcknowledged = (isAcknowledged: boolean) => {
    dispatch({ type: UseCheckoutGlobalReducerActions.SET_CHECKOUT_DATA, payload: { isAcknowledged } });
  };
  const setIsAcknowledgedEditMode = (isAcknowledgedEditMode: boolean) => {
    dispatch({ type: UseCheckoutGlobalReducerActions.SET_CHECKOUT_DATA, payload: { isAcknowledgedEditMode } });
  };
  const setIsCheckboxError = (isCheckboxError: boolean) => {
    dispatch({ type: UseCheckoutGlobalReducerActions.SET_CHECKOUT_DATA, payload: { isCheckboxError } });
  };
  const setIsCheckboxErrorEditMode = (isCheckboxErrorEditMode: boolean) => {
    dispatch({ type: UseCheckoutGlobalReducerActions.SET_CHECKOUT_DATA, payload: { isCheckboxErrorEditMode } });
  };
  const setShoppingCategoriesOpen = (shoppingCategoriesOpen: ShoppingCategoriesOpenProps) => {
    dispatch({ type: UseCheckoutGlobalReducerActions.SET_CHECKOUT_DATA, payload: { shoppingCategoriesOpen } });
  };
  const setDeliveryMinimums = (deliveryMinimums: DeliveryWindow.DeliveryMinimum[]) => {
    dispatch({ type: UseCheckoutGlobalReducerActions.SET_CHECKOUT_DATA, payload: { deliveryMinimums } });
  };
  const setIsValidDeliveryDate = (isValidDeliveryDate: boolean) => {
    dispatch({ type: UseCheckoutGlobalReducerActions.SET_CHECKOUT_DATA, payload: { isValidDeliveryDate } });
  };

  // Marketing Messages for Edit and Normal Checkout
  const setMarketingMessage = (marketingMessage: MarketingMessage) => {
    dispatch({ type: UseCheckoutGlobalReducerActions.SET_CHECKOUT_DATA, payload: { marketingMessage } });
  };
  const setMarketingMessageEdit = (marketingMessageEdit: MarketingMessage) => {
    dispatch({ type: UseCheckoutGlobalReducerActions.SET_CHECKOUT_DATA, payload: { marketingMessageEdit } });
  };

  const geocodeCutoffDate = state.windows?.data?.[0]?.geocodeCutoffDate;
  const geocodeCutoffTime = state.windows?.data?.[0]?.geocodeCutoffTime;
  const deliveryWindows = state.windows?.data?.[0]?.deliveryWindows;

  const eligibilityResponseTime = useRef(0);
  const deliveryWindowsResponseTime = useRef(0);

  const isCheckboxChecked = (isButtonEnabled: boolean, isEditCheckout: boolean) => {
    if (!isButtonEnabled) {
      isEditCheckout ? setIsCheckboxErrorEditMode(true) : setIsCheckboxError(true);
      scrollToElementById();
      return false;
    }

    return true;
  };

  const resetCheckboxValue = (editMode: boolean) => {
    dispatch({
      type: UseCheckoutGlobalReducerActions.SET_CHECKOUT_DATA,
      payload: editMode ? { isAcknowledgedEditMode: false } : { isAcknowledged: false },
    });
  };

  const resetAcknowledgedCheckBox = useCallback(() => {
    setIsCheckboxError(false);
    setIsCheckboxErrorEditMode(false);
    dispatch({
      type: UseCheckoutGlobalReducerActions.SET_CHECKOUT_DATA,
      payload: { isAcknowledgedEditMode: false, isAcknowledged: false },
    });
  }, []);

  //This will be used to display the info in checkout success page
  const [, setPrimaryOrder] = useLocalStorage(CHECKOUT_PRIMARY_ORDER, null);
  const [, setSecondaryOrder] = useLocalStorage(CHECKOUT_SECONDARY_ORDER, null);

  const persistedPrimaryOrder = useRef<PersistedOrder | null>(null);
  const persistedSecondaryOrder = useRef<PersistedOrder | null>(null);

  const { cart, dispatchCart, fetchAllCarts, setDraftCart } = cartActions;

  const trackPurchase = usePurchaseAlgoliaTracking(userData?.state, logout, cart);

  const lineItemsForSplitOrder = useMemo(() => {
    if (isEditingOrder) {
      return editCart?.lineItems;
    }
    return cart?.lineItems;
  }, [JSON.stringify(cart?.lineItems), isEditingOrder, JSON.stringify(editCart?.lineItems)]);

  const skus = useMemo(() => {
    return lineItemsForSplitOrder?.map((item) => {
      if (isEditingOrder) {
        return item.productNumber;
      }
      return item.variant?.sku;
    });
  }, [lineItemsForSplitOrder, lineItemsForSplitOrder?.length, isEditingOrder]);

  async function extraDataFetcher(skus: string[]) {
    try {
      const extraData = await getProductExtraData(
        skus,
        userData.state,
        toast,
        formatMessage({ id: 'app.generic.error' }),
        logout,
      );
      const productExtraData = extraData.map(({ productExtraData }) => productExtraData);
      setExtraProductData(productExtraData);
    } catch (e) {
      console.error(e);
    }
  }

  // Called every time the sku list change?
  useEffect(() => {
    if (!isLoadingUserData && skus?.length > 0) {
      extraDataFetcher(skus);
    }
  }, [skus?.length, isLoadingUserData, isEditingOrder]);

  // Split order hook
  const { orderGroups, isSplitOrder: _isSplitOrder } = useSplitOrder({
    isEditOrder: isEditingOrder,
    lineItems: lineItemsForSplitOrder,
    setCutOffDeliveryDates,
    geocodeDeliveryDate,
    activeAccount,
    softCutoffs,
    extraProductData,
    editCart,
  });

  const isSplitOrder = _isSplitOrder && (!isEditingOrder || (isEditingOrder && FEATURE_FLAGS.SPLIT_ORDER_EDIT_ORDER));

  // return a vector where the first array contains the products for the first order from orderGroups and the second array contains the products for the second order from orderGroups
  // when it's not split order, the second array will be empty
  const productForDeliveryWindow = useMemo(() => {
    if (orderGroups?.length === 0 && !isEditingOrder) {
      return [[], []];
    }

    if (isEditingOrder && !isSplitOrder) {
      return [
        (editCart?.lineItems || []).map((lineItem) => ({
          quantity: lineItem.orderedQuantity || 1,
          productNumber: lineItem.productNumber,
          lineNumber: lineItem.lineNumber,
        })),
        [],
      ];
    }

    if (orderGroups?.length === 0) {
      return [[], []];
    }

    if (isSplitOrder) {
      return orderGroups.map((orderGroup) =>
        orderGroup?.lineItems?.map((lineItem) => ({
          productNumber: lineItem?.productNumber || lineItem?.variant?.sku,
          quantity: lineItem.count || 1,
        })),
      );
    }

    return [
      (cart?.lineItems || []).map((lineItem) => ({
        productNumber: lineItem.variant?.sku,
        quantity: lineItem.count || 1,
      })),
      [],
    ];
  }, [
    orderGroups,
    isEditingOrder,
    isSplitOrder,
    JSON.stringify(cart?.lineItems),
    JSON.stringify(editCart?.lineItems),
    geocodeDeliveryDate,
    softCutoffs,
  ]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const primaryOrder = window.localStorage.getItem(CHECKOUT_PRIMARY_ORDER);
      const secondaryOrder = window.localStorage.getItem(CHECKOUT_SECONDARY_ORDER);

      if (primaryOrder && !persistedPrimaryOrder.current) {
        const parsedPrimaryOrder = JSON.parse(primaryOrder);
        setPrimaryOrder(parsedPrimaryOrder);
        persistedPrimaryOrder.current = parsedPrimaryOrder;
      }

      if (secondaryOrder && !persistedSecondaryOrder.current) {
        const parsedSecondaryOrder = JSON.parse(secondaryOrder);
        setSecondaryOrder(parsedSecondaryOrder);
        persistedSecondaryOrder.current = parsedSecondaryOrder;
      }
    }
  }, []);

  // Updated when account change?
  const eligibilitiesPayload = useMemo(() => {
    return getPayloadByWarehouses(accountList);
  }, [activeWarehouse, accessToken, activeAccount, accountList]);

  //We use this effect here to have prepared the delivery windows for the user once they reach the checkout page
  useEffect(() => {
    if (shouldCallEligibilityEndpoint(asPath)) {
      loadEligibilities();
    }
  }, [asPath, accessToken]);

  const { currentUserEligibleOptions, deliveryOptionsEligibilities, eligibleOptionsByCustomers } =
    state.eligibility?.data || {};
  /**
   * Get Windows for Split & regular orders
   */
  async function getDeliveryMinimums() {
    const firstOrder = await loadDeliveryWindows(
      productForDeliveryWindow[0],
      isEditingOrder ? editCart?.orderNumber : undefined,
      state.eligibility?.data?.currentUserEligibleOptions?.[KEY_PICK_UP] || false,
      state.eligibility?.data?.currentUserEligibleOptions?.[KEY_A_LA_CARTE] || false,
      state.checkoutData.isPlacingOrder,
      isEditingOrder ? editCart?.estimatedDeliveryDate : undefined,
    );
    const secondOrder = await loadDeliveryWindows(
      productForDeliveryWindow[1],
      undefined,
      false,
      false,
      state.checkoutData.isPlacingOrder,
    );

    const orders = [firstOrder, secondOrder].filter((order) => !!order);

    dispatch({
      type: UseCheckoutGlobalReducerActions.SET_WINDOWS,
      payload: { data: orders.length ? orders : null },
    });
  }

  // Called after the new extraData is loaded? (every time the SKU list changes)
  useEffect(() => {
    if (
      productForDeliveryWindow[0].length > 0 &&
      !Object.values(state.windows.isLoading).some((value) => value) &&
      !state.eligibility.isLoading
    ) {
      getDeliveryMinimums();
    }
  }, [productForDeliveryWindow[0].length, isEditingOrder, extraProductData, state.eligibility.data]);

  // Called every time the delivery windows change
  useEffect(() => {
    if (!state.windows.data) {
      return;
    }
    const nextDeliveryMinimums = [
      ...(state.windows?.data?.[0]?.deliveryMinimums || []),
      ...(state.windows?.data?.[1]?.deliveryMinimums || []),
    ];

    if (
      nextDeliveryMinimums &&
      JSON.stringify(nextDeliveryMinimums) !== JSON.stringify(state.checkoutData.deliveryMinimums)
    ) {
      setDeliveryMinimums(nextDeliveryMinimums);
    }

    if (geocodeCutoffDate && geocodeCutoffTime) {
      const geocodeDate = `${geocodeCutoffDate}T${geocodeCutoffTime}.000Z`;
      setGeocodeDeliveryDate(geocodeDate);
    }
  }, [state.windows.data, extraProductData]);

  const { getBillingAddress, getShippingAddress, getCustomerEmail, getCustomFields } = useUserHelpers(userData);

  const setComments = (comments: string) =>
    dispatch({ type: UseCheckoutGlobalReducerActions.SET_CHECKOUT_DATA, payload: { comments } });

  const setDeliveryDate = (deliveryDate: string) =>
    dispatch({
      type: UseCheckoutGlobalReducerActions.SET_CHECKOUT_DATA,
      payload: { deliveryDate },
    });

  const setSecondaryDate = (secondaryDate: string) =>
    dispatch({
      type: UseCheckoutGlobalReducerActions.SET_CHECKOUT_DATA,
      payload: { secondaryDate },
    });

  const setPONumber = (poNumber: string) =>
    dispatch({ type: UseCheckoutGlobalReducerActions.SET_CHECKOUT_DATA, payload: { poNumber } });

  const setShippingMethod = (shippingMethod: string) =>
    dispatch({
      type: UseCheckoutGlobalReducerActions.SET_CHECKOUT_DATA,
      payload: { shippingMethod },
    });

  const setDeliveryWindow = (deliveryWindow: string) =>
    dispatch({
      type: UseCheckoutGlobalReducerActions.SET_CHECKOUT_DATA,
      payload: { deliveryWindow },
    });

  const setGeocode = (geocode: string) =>
    dispatch({ type: UseCheckoutGlobalReducerActions.SET_CHECKOUT_DATA, payload: { geocode } });

  const setWarehouseAddress = (warehouseAddress: Partial<Address>) =>
    dispatch({
      type: UseCheckoutGlobalReducerActions.SET_CHECKOUT_DATA,
      payload: { warehouseAddress },
    });

  const updateSplittedOrder = async () => {
    window.localStorage.setItem(IS_SPLIT_ORDER, 'true');

    toggleOrderUpdatingState(true);

    try {
      // we need first to which group carries the original order items
      let originalOrderGroup = orderGroups[0];
      let newOrderGroup = orderGroups[1];
      orderGroups.map((group, index) => {
        const isOriginalGroup = group.lineItems.some((lineItem) => {
          return lineItem?.hasOwnProperty('lineNumber');
        });

        if (isOriginalGroup) {
          originalOrderGroup = group;
          return group;
        } else {
          newOrderGroup = group;
        }
      });

      //eslint-disable-next-line
      const regularOrder = (originalOrderGroup.lineItems as OrderDetailLineItem[]).map<LineItem>((item) => ({
        variant: {
          sku: item.productNumber,
        },
        count: item.orderedQuantity,
        price: {
          centAmount: item.originalPrice,
          currencyCode: DEFAULT_CURRENCY,
        },
      }));

      const splitOrder = (newOrderGroup.lineItems as OrderDetailLineItem[]).map<LineItem>((item) => ({
        variant: {
          sku: item.productNumber,
        },
        count: item.orderedQuantity,
        price: {
          centAmount: item.originalPrice,
          currencyCode: DEFAULT_CURRENCY,
        },
      }));

      const commonFields = {
        shipping: getShippingAddress(),
        billing: getBillingAddress(),
        account: getCustomerEmail(),
        poNumber: state.checkoutData.poNumber,
      };

      const cartDetailsMainOrder = {
        shipping: getShippingAddress(),
        billing: getBillingAddress(),
        account: getCustomerEmail(),
        custom: getCustomFields({
          deliveryDate: state.checkoutData.deliveryDate,
          specialHandlingInstructions: state.checkoutData.comments,
          selectedShippingMethod: state.checkoutData.shippingMethod,
          geocode: state.checkoutData.geocode,
        }),
      };

      const cartDetailsSplitOrder = {
        shipping: getShippingAddress(),
        billing: getBillingAddress(),
        account: getCustomerEmail(),
        custom: getCustomFields({
          deliveryDate: state.checkoutData.secondaryDate,
          specialHandlingInstructions: state.checkoutData.comments,
          selectedShippingMethod: DEFAULT_SHIPPING_METHOD,
          geocode: state.checkoutData.geocode,
        }),
      };

      let activeCart = cart;

      if (!activeCart || activeCart?.cartId == DraftCartValues.CART_ID) {
        activeCart = await getCart(toast, formatMessage({ id: GENERIC_TOAST_ERROR_ID }));
      }

      const checkoutResponse = await actionSplitCart({
        ...commonFields,
        cartId: activeCart?.cartId,
        lineItems: splitOrder,
        shipping: getShippingAddress(),
        billing: getBillingAddress(),
        account: getCustomerEmail(),
        custom: cartDetailsSplitOrder.custom,
        deliveryType: DEFAULT_SHIPPING_METHOD,
        deliveryDate: state.checkoutData.secondaryDate,
        geocode: state.checkoutData.geocode,
        instructions: state.checkoutData.comments,
        poNumber: state.checkoutData.poNumber,
        updateOriginalCart: {
          ...commonFields,
          customFields: cartDetailsMainOrder.custom,
        },
      });

      // we only consider it as failed if both orders are missing
      // regular order sometimes is empty due to all lineItems not being able to edit
      if (!checkoutResponse?.mainOrder && !checkoutResponse?.splitOrder) {
        throw new Error('Error API: order on splitCart');
      }

      if (checkoutResponse?.splitOrder) {
        updateSplitOrder(editCart.orderNumber, checkoutResponse?.splitOrder);
      } else {
        throw new Error('Error API: checkout');
      }

      const payload = mountUpdateOrderPayload({
        ...editCart,
        lineItems: originalOrderGroup.lineItems as OrderDetailLineItem[]
      });

      await updateCustomerOrderDetail(payload, toast, formatMessage({ id: 'app.generic.error' }));
    } catch (error) {
      // If API is not successful then the user stays on the
      // checkout page and toast error is displayed for 5 seconds

      toggleOrderUpdatingState(false);
      console.error('Error API: updateCustomerOrderDetail', error);
      if (!toast.isActive(GENERIC_TOAST_ERROR_ID)) {
        toast({
          duration: 5000,
          status: 'error',
          title: formatMessage({ id: 'editOrder.checkout.toast.error' }),
          icon: TOAST_ICON.error,
        });
      }
      return;
    }

    // delay the redirect to allow the API to complete the update
    await sleep(3000);

    // If API is successful, user is taken back to the Order details
    //  with toast success message is displayed for 5 seconds
    router.push(`${routes.ORDER_DETAIL_PAGE}${editCart.customerNumber}/${editCart.orderNumber}`);

    // delay the toast to allow the page to redirect
    await sleep(1000);
    toast({
      duration: 5000,
      status: 'success',
      title: formatMessage({ id: 'editOrder.checkout.toast.success' }),
      icon: TOAST_ICON.success,
    });
    disableOrderEditing();

    setTimeout(() => {
      window.localStorage.removeItem(IS_SPLIT_ORDER);
    }, 1000);
  };

  const placeOrder = async () => {
    try {
      dispatch({
        type: UseCheckoutGlobalReducerActions.SET_CHECKOUT_DATA,
        payload: { orderPlaced: true, isPlacingOrder: true },
      });
      _persistedIsSplitOrder.current = isSplitOrder;
      persistedLineItems.current = cart?.lineItems ?? [];
      persistedGroups.current = [...orderGroups];
      persistedOrderTotal.current = cart?.sum?.centAmount;
      persistedDeliveryWindows.current = {
        deliveryWindows: { ...deliveryWindows },
        isLoadingDeliveryWindows: state.windows?.isLoading,
      };
      if (!cart.lineItems?.length) {
        toast({
          duration: 5000,
          status: 'error',
          title: formatMessage({ id: 'editOrder.cart.empty' }),
          icon: TOAST_ICON.error,
        });
        return;
      }

      trackPurchase();
      _persistedIsSplitOrder.current ? await placeSplittedOrder() : await placeSingleOrder();
      localStorage.removeItem('selectedCartId');
      setDraftCart();
      await fetchAllCarts();
    } catch (e) {
      console.error('Error API call: placeOrder', e);
      if (!toast.isActive(GENERIC_TOAST_ERROR_ID)) {
        toast({
          duration: 5000,
          status: 'error',
          title: formatMessage({ id: 'app.generic.error' }),
          id: GENERIC_TOAST_ERROR_ID,
          icon: TOAST_ICON.error,
        });
      }
    } finally {
      delay(
        () => dispatch({ type: UseCheckoutGlobalReducerActions.SET_CHECKOUT_DATA, payload: { isPlacingOrder: false } }),
        1500,
      );
      setTimeout(() => {
        _persistedIsSplitOrder.current = false;
        persistedLineItems.current = null;
        persistedGroups.current = null;
        persistedOrderTotal.current = null;
        persistedDeliveryWindows.current = null;
        dispatch({ type: UseCheckoutGlobalReducerActions.SET_CHECKOUT_DATA, payload: { orderPlaced: false } });
      }, 3000);
    }
  };

  const placeSingleOrder = async () => {
    persistedPrimaryOrder.current = null;
    persistedSecondaryOrder.current = null;
    setPrimaryOrder(null);
    setSecondaryOrder(null);

    try {
      const resCheckout = await checkout(
        {
          poNumber: state.checkoutData.poNumber,
          shippingAddress: getShippingAddress(),
          billingAddress: getBillingAddress(),
          account: getCustomerEmail(),
          customFields: getCustomFields({
            deliveryDate: state.checkoutData.deliveryDate,
            specialHandlingInstructions: state.checkoutData.comments,
            selectedShippingMethod: state.checkoutData.shippingMethod,
            geocode: state.checkoutData.geocode,
          }),
        },
        null,
        cart.cartId,
      );

      if (resCheckout?.order) {
        setPONumber('');
        setComments('');
        const regularResponse = { order: resCheckout.order };
        setPrimaryOrder(regularResponse);
        persistedPrimaryOrder.current = regularResponse;
        router.push(routes.CHECKOUT_SUCCESS);
      }
    } catch (error) {
      console.error('Error API call: placeSingleOrder', error);
      if (!toast.isActive(GENERIC_TOAST_ERROR_ID)) {
        toast({
          duration: 5000,
          status: 'error',
          title: formatMessage({ id: 'app.generic.error' }),
          id: GENERIC_TOAST_ERROR_ID,
          icon: TOAST_ICON.error,
        });
      }
    }
  };

  const placeSplittedOrder = async () => {
    persistedPrimaryOrder.current = null;
    persistedSecondaryOrder.current = null;
    setPrimaryOrder(null);
    setSecondaryOrder(null);

    try {
      window.localStorage.setItem(IS_SPLIT_ORDER, 'true');
      // Split order in two carts
      // For the first cart, the line items from the second cart are deleted, then proceed to checkout
      const secondaryCartItems = orderGroups[1].lineItems as LineItem[];

      const commonFields = {
        shipping: getShippingAddress(),
        billing: getBillingAddress(),
        account: getCustomerEmail(),
        poNumber: state.checkoutData.poNumber,
      };

      const mainOrderCustomFields = getCustomFields({
        deliveryDate: state.checkoutData.deliveryDate,
        specialHandlingInstructions: state.checkoutData.comments,
        selectedShippingMethod: state.checkoutData.shippingMethod,
        geocode: state.checkoutData.geocode,
      });

      const splitOrderCustomFields = getCustomFields({
        deliveryDate: state.checkoutData.secondaryDate,
        specialHandlingInstructions: state.checkoutData.comments,
        selectedShippingMethod: DEFAULT_SHIPPING_METHOD,
        geocode: state.checkoutData.geocode,
      });

      const splitCheckoutResponse = await actionSplitCart({
        ...commonFields,
        cartId: cart?.cartId,
        lineItems: secondaryCartItems,
        shipping: getShippingAddress(),
        billing: getBillingAddress(),
        account: getCustomerEmail(),
        custom: splitOrderCustomFields,
        deliveryType: DEFAULT_SHIPPING_METHOD,
        deliveryDate: state.checkoutData.secondaryDate,
        geocode: state.checkoutData.geocode,
        instructions: state.checkoutData.comments,
        poNumber: state.checkoutData.poNumber,
        updateOriginalCart: {
          ...commonFields,
          customFields: mainOrderCustomFields,
          poNumber: state.checkoutData.poNumber,
        },
      });

      if (splitCheckoutResponse?.splitOrder) {
        const splitResponse = { order: splitCheckoutResponse.splitOrder };
        persistedSecondaryOrder.current = splitResponse;
        setSecondaryOrder(splitResponse);
      }

      if (splitCheckoutResponse?.mainOrder) {
        const regularResponse = { order: splitCheckoutResponse.mainOrder };
        persistedPrimaryOrder.current = regularResponse;
        setPrimaryOrder(regularResponse);
      }

      if (splitCheckoutResponse?.updatedOriginalCart) {
        dispatchCart({
          type: CartGlobalStateActions.UPDATE,
          payload: { cart: splitCheckoutResponse.updatedOriginalCart },
        });
      }
      setPONumber('');
      setComments('');
      router.push(routes.CHECKOUT_SUCCESS);

      setTimeout(() => {
        window.localStorage.removeItem(IS_SPLIT_ORDER);
      }, 1000);
    } catch (error) {
      console.error('Error API call: placeSplittedOrder', error);
      if (!toast.isActive(GENERIC_TOAST_ERROR_ID)) {
        toast({
          duration: 5000,
          status: 'error',
          title: formatMessage({ id: 'app.generic.error' }),
          id: GENERIC_TOAST_ERROR_ID,
          icon: TOAST_ICON.error,
        });
      }
    }
  };

  const openShoppingCategories = useCallback((str: ShoppingCategoriesOpenProps) => {
    setShoppingCategoriesOpen(str);
  }, []);

  const closeShoppingCategories = useCallback(() => {
    setShoppingCategoriesOpen(null);
  }, []);

  /**
   * Loads and formats elegibilities data
   */
  const loadEligibilities = useCallback(async () => {
    const isEligilitiesPayloadValid = eligibilitiesPayload.every(
      (el) => el.customerNumbers.length > 0 && Object.values(el).every((val) => !!val),
    );
    try {
      const startTime = Date.now();
      dispatch({ type: UseCheckoutGlobalReducerActions.SET_ELIGIBILITY, payload: { isLoading: true } });
      if (isEligilitiesPayloadValid && accessToken && !isPrintBack && activeAccount) {
        const data = await getDeliveryOptionsEligibilities(
          accessToken,
          eligibilitiesPayload,
          toast,
          formatMessage({ id: 'app.generic.error' }),
        );
        eligibilityResponseTime.current = Date.now() - startTime;
        dispatch({ type: UseCheckoutGlobalReducerActions.SET_ELIGIBILITY, payload: { isLoading: false } });

        if (data?.length > 0) {
          const formattedData = formatDeliveryOptionsEligibilitiesResponse(data) ?? {};
          dispatch({
            type: UseCheckoutGlobalReducerActions.SET_ELIGIBILITY,
            payload: {
              data: {
                deliveryOptionsEligibilities: data,
                eligibleOptionsByCustomers: formattedData,
                currentUserEligibleOptions: formattedData?.[activeAccount?.key] ?? {},
              },
            },
          });
        }
      }
    } catch (e) {
      console.error('Error loadEligibilities:', e);
      if (!toast.isActive(GENERIC_TOAST_ERROR_ID)) {
        toast({
          duration: 5000,
          status: 'error',
          title: formatMessage({ id: 'app.generic.error' }),
          id: GENERIC_TOAST_ERROR_ID,
          icon: TOAST_ICON.error,
        });
      }
    }
    dispatch({ type: UseCheckoutGlobalReducerActions.SET_ELIGIBILITY, payload: { isLoading: false } });
  }, [eligibilitiesPayload, accessToken, asPath]);

  const logCheckoutDeliveryDates = (
    data: any,
    extraProductData: any[],
    orderNumber?: string,
    eligibleForPickup?: boolean,
    eligibleForALaCarte?: boolean,
    productsLength?: number,
  ) => {
    if (window.LogRocket) {
      window.LogRocket.track('CheckoutDeliveryDates', {
        deliveriesBeforeGeocodeCutoff: data.deliveryOptions
          .filter((option) => option.deliveryType === A_LA_CARTE || option.deliveryType === WILL_CALL)
          .map((option) => `${option.deliveryType}: ${option.deliveryDate}`),

        geocodeCutoffDate: `${data.geocodeCutoffDate}T${data.geocodeCutoffTime}.000Z`,

        earliestPossibleDeliveryDates: extraProductData.map(
          (product) => `${product.productNumber}: ${calculateLatestDeliveryDate([product])}`,
        ),

        orderNumber: orderNumber || 'null',
        isSplitDelivery: eligibleForPickup && eligibleForALaCarte,
        totalProducts: productsLength || 0,
      });
    }
  };

  /**
   * Loads deliveryWindows
   */
  const loadDeliveryWindows = useCallback(
    async (
      products: ProductsForDeliveryWindow[],
      orderNumber?: string,
      eligibleForPickup?: boolean,
      eligibleForALaCarte?: boolean,
      placingOrder: boolean = false,
      editOrderDeliveryDateStr?: string,
    ): Promise<DeliveryWindows> => {
      const productsForDeliveryDate = products.map((product) => {
        if ((product as any).lineNumber == null) {
          return extraProductData.find((extra) => extra.productNumber === product.productNumber);
        }
        return null;
      });
      const oldProductsForDeliveryDate = products.map((product) => {
        if ((product as any).lineNumber != null) {
          return extraProductData.find((extra) => extra.productNumber === product.productNumber);
        }
        return null;
      });

      if (
        extraProductData?.length &&
        (productsForDeliveryDate?.length || oldProductsForDeliveryDate?.length) &&
        [routes.CHECKOUT, routes.EDIT_ORDER_CHECKOUT].includes(asPath)
      ) {
        const startTime = Date.now();
        let latestDeliveryDate = calculateLatestDeliveryDate(productsForDeliveryDate);
        let oldLatestDeliveryDate = calculateLatestDeliveryDate(oldProductsForDeliveryDate);
        if (editOrderDeliveryDateStr != null) {
          const editOrderDeliveryDate = new Date(`${editOrderDeliveryDateStr}T12:00:00.000Z`);
          if (
            isBefore(editOrderDeliveryDate, oldLatestDeliveryDate) &&
            isBefore(latestDeliveryDate, editOrderDeliveryDate)
          ) {
            latestDeliveryDate = editOrderDeliveryDate;
          }
        }

        try {
          const now = new Date();
          const todayDate = new Date(
            Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 23, 59, 59, 999),
          );

          if (isBefore(latestDeliveryDate, todayDate)) {
            latestDeliveryDate = null;
          } else {
            // If in the future, update date to be the end of the previous day
            latestDeliveryDate.setUTCDate(latestDeliveryDate.getUTCDate() - 1);
            // Set time to the end of the day in UTC (23:59:59.999)
            latestDeliveryDate.setUTCHours(23, 59, 59, 999);
          }

          const windowPayload = {
            warehouseNumber: activeWarehouse?.warehouseNumber,
            businessUnitName: activeWarehouse?.businessUnit,
            businessSegmentName: activeWarehouse?.businessSegment,
            customerNumber: activeAccount?.key,
            products,
            startingAfter: null,
            orderNumber,
          };

          const isValid =
            !!windowPayload &&
            every(values(windowPayload), (val) => val !== '' && !(Array.isArray(val) && val.length === 0));
          const hasCreditOnHold = hasCreditOnHoldInfo(activeAccount);
          if (isValid && (!hasCreditOnHold && products.length > 0, !placingOrder)) {
            dispatch({
              type: UseCheckoutGlobalReducerActions.SET_WINDOWS,
              payload: {
                isLoading: {
                  [KEY_A_LA_CARTE]: true,
                  [KEY_PICK_UP]: true,
                },
              },
            });
            if (eligibleForPickup && !eligibleForALaCarte) {
              windowPayload['method'] = WILL_CALL;
            } else if (eligibleForALaCarte && !eligibleForPickup) {
              windowPayload['method'] = A_LA_CARTE;
            }
            const data = await deliveryOptions([
              BASE_URL,
              {
                payload: windowPayload,
                accessToken,
                logout,
                toast,
                toastMessage: formatMessage({ id: 'app.generic.error' }),
              },
            ]);

            logCheckoutDeliveryDates(
              data,
              extraProductData,
              orderNumber,
              eligibleForPickup,
              eligibleForALaCarte,
              products.length,
            );

            deliveryWindowsResponseTime.current = Date.now() - startTime;
            const aLaCarteOptionsList = data?.deliveryOptions
              .filter((option) => option?.deliveryType === A_LA_CARTE)
              .slice(0, MAX_DELIVERY_DATES);

            const pickupOptionsList = data?.deliveryOptions
              .filter((option) => option?.deliveryType === WILL_CALL)
              .slice(0, MAX_DELIVERY_DATES);
            const windows: DeliveryWindows = {
              geocodeCutoffDate: data?.geocodeCutoffDate,
              geocodeCutoffTime: data?.geocodeCutoffTime,
              deliveryWindows: {
                [KEY_A_LA_CARTE]: (aLaCarteOptionsList as FormattedDeliveryOption[]) || [],
                [KEY_PICK_UP]: (pickupOptionsList as FormattedDeliveryOption[]) || [],
              },
              deliveryMinimums: data?.deliveryMinimums || [],
            };
            dispatch({
              type: UseCheckoutGlobalReducerActions.SET_WINDOWS,
              payload: {
                isLoading: {
                  [KEY_A_LA_CARTE]: false,
                  [KEY_PICK_UP]: false,
                },
              },
            });
            return windows;
          }
        } catch (e) {
          console.error(e);
          dispatch({
            type: UseCheckoutGlobalReducerActions.SET_WINDOWS,
            payload: {
              isLoading: {
                [KEY_A_LA_CARTE]: false,
                [KEY_PICK_UP]: false,
              },
            },
          });
        }
      }
    },
    [
      accessToken,
      activeWarehouse?.warehouseNumber,
      activeWarehouse?.businessUnit,
      activeWarehouse?.businessSegment,
      activeAccount?.key,
      productForDeliveryWindow,
      extraProductData,
      asPath,
    ],
  );

  const checkout = useCallback(
    async (order: CheckoutCart, splitOrder?: CheckoutCart, cartId?: string): Promise<CheckoutResponse> => {
      try {
        const checkoutResponse: CheckoutResponse = {
          order: null,
          splitOrder: null,
        };

        let splitCartResponse: { splitOrder: Order; mainOrder: Order; updatedOriginalCart: Cart } | undefined;

        if (splitOrder) {
          const splitOrderPayload = {
            cartId: cartId,
            lineItems: splitOrder.lineItems,
            shipping: splitOrder.shippingAddress,
            billing: splitOrder.billingAddress,
            account: splitOrder.account,
            custom: splitOrder.customFields,
            deliveryType: splitOrder.deliveryType,
            deliveryDate: splitOrder.deliveryDate,
            geocode: splitOrder.geocode,
            instructions: splitOrder.instructions,
            poNumber: splitOrder.poNumber,
            updateOriginalCart: {
              ...order,
              shipping: order.shippingAddress,
              billing: order.billingAddress,
              account: order.account,
              custom: order.customFields,
            },
          };

          splitCartResponse = await actionSplitCart(splitOrderPayload);

          if (splitCartResponse?.splitOrder) {
            checkoutResponse.splitOrder = splitCartResponse.splitOrder;
          }
          if (splitCartResponse?.mainOrder) {
            checkoutResponse.order = splitCartResponse.mainOrder;
          }
        } else {
          const mainOrderPayload = {
            ...order,
            shipping: order.shippingAddress,
            billing: order.billingAddress,
            account: order.account,
            custom: order.customFields,
            cartId: cartId,
          };
          const response = await actionCheckout(mainOrderPayload, cartId);
          if (response?.order) {
            checkoutResponse.order = response.order;
          }
        }

        if (splitCartResponse?.updatedOriginalCart) {
          dispatchCart({
            type: CartGlobalStateActions.UPDATE,
            payload: { cart: splitCartResponse.updatedOriginalCart },
          });
        } else {
          setDraftCart();
        }

        return checkoutResponse;
      } catch (error) {
        console.error('GLOBAL Cart error: checkout', error);
        if (!toast.isActive(GENERIC_TOAST_ERROR_ID)) {
          toast({
            duration: 5000,
            status: 'error',
            title: formatMessage({ id: 'app.generic.error' }),
            id: GENERIC_TOAST_ERROR_ID,
            icon: TOAST_ICON.error,
          });
        }
        dispatchCart({ type: CartGlobalStateActions.SET_ERROR, payload: { error: error } });
      }
    },
    [isLoadingUserData],
  );

  return {
    ...state.checkoutData,
    placeOrder,
    setComments,
    setDeliveryDate,
    setSecondaryDate,
    setPONumber,
    setShippingMethod,
    setDeliveryWindow,
    setDeliveryMinimums,
    setWarehouseAddress,
    setGeocode,
    persistedGroups: persistedGroups.current,
    persistedLineItems: persistedLineItems.current,
    persitedOrderTotal: persistedOrderTotal.current,
    persistedPrimaryOrder: persistedPrimaryOrder.current,
    persistedSecondaryOrder: persistedSecondaryOrder.current,
    persistedDeliveryWindows: persistedDeliveryWindows.current,
    deliveryWindows,
    eligibility: {
      options: deliveryOptionsEligibilities,
      byCustomers: eligibleOptionsByCustomers,
      byType: currentUserEligibleOptions,
      isLoading: state.eligibility?.isLoading,
    },
    isLoadingDeliveryWindows: state.windows?.isLoading,
    orderGroups: orderGroups,
    isSplitOrder: isSplitOrder || _persistedIsSplitOrder.current,
    state: state.checkoutData,
    openShoppingCategories,
    closeShoppingCategories,
    setIsAcknowledged,
    setIsAcknowledgedEditMode,
    resetCheckboxValue,
    setCutOffDeliveryDates,
    setIsValidDeliveryDate,
    setMarketingMessage,
    setMarketingMessageEdit,
    updateSplittedOrder,
    setIsCheckboxError,
    setIsCheckboxErrorEditMode,
    isCheckboxChecked,
    eligibilityResponseTime: eligibilityResponseTime.current,
    deliveryWindowsResponseTime: deliveryWindowsResponseTime.current,
    resetAcknowledgedCheckBox,
    radioButtonStates,
    setRadioButtonStates,
  };
}
