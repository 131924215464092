import { useEffect, useState } from 'react';
import NextLink from 'next/link';
import {
  Box,
  Center,
  Spinner,
  Flex,
  Text,
  Image,
  Button,
  useBreakpointValue,
  Link,
  VStack,
  Icon,
  useToast,
} from '@chakra-ui/react';
import { useGlobal } from 'components/globalProvider';
import { analyticsTrackPurchase } from 'composable/analytics/analytics-event-tracking';
import { CustomSkeleton } from 'composable/components/custom-skeleton';
import { ContentContainer, TOAST_ICON } from 'composable/components/general';
import { useGetSplitOrder } from 'composable/components/product-card-v2/helpers/hooks';
import { useUserHelpers } from 'composable/helpers/hooks/useUserHelpers';
import { getCartSummaryAmounts } from 'composable/helpers/utils/cart-utils';
import { hasCreditOnHoldInfo } from 'composable/helpers/utils/user-utils';
import { format } from 'date-fns/format';
import { CAN_SUBMIT_ORDERS } from 'helpers/constants/userPermissions';
import { useFormat } from 'helpers/hooks/useFormat';
import { ArrowLeft } from 'phosphor-react';
import { useProtectSuccessPage } from '../hooks/useProtectSuccessPage';
import { BasicPanel } from '../parts/basic-panel';
import { BillTo } from '../parts/bill-to';
import { DeliveryTo } from '../parts/delivery-to';
import { OrderSummary } from '../parts/order-summary';
import { SplitOrderSummary } from '../parts/split-order-summary';
import { TextBlock } from '../parts/text-block';
import { KEY_NAME_PICK_UP } from '../utils/constants';
import getShippingMethodTranslationKey from '../utils/getShippingMethodTranslationKey';
import { useRouter } from 'next/router';
import routes from 'helpers/constants/routes';

export const formatDeliveryDate = (date: string | Date) => {
  if (!date || date === 'N/A') return '';

  const parsedDate = new Date(date);
  const formattedDate = format(parsedDate, 'MM/dd/yyyy');

  return formattedDate;
};

const LOADER_DURATION = 3000;
const LOADING_STATE_DURATION = 1500;

export const CheckoutSuccessPage = () => {
  useProtectSuccessPage();
  const router = useRouter();
  const { formatMessage } = useFormat({ name: 'common' });
  const toast = useToast();
  const { persistedPrimaryOrder, persistedSecondaryOrder, state } = useGlobal().useCheckoutGlobal;
  const { warehouseAddress } = state || {};
  const {
    state: { activeAccount, loading: isLoadingUserContext, shamrockUser },
  } = useGlobal().useUserGlobal;
  const permissions = shamrockUser?.user?.permissions?.data ?? [];

  const { loading: isCartLoading } = useGlobal().useCartGlobal;

  const canSubmitOrders = permissions.includes(CAN_SUBMIT_ORDERS);

  const { getCompanyName } = useUserHelpers();

  const [hideSplitOrder, setHideSplitOrder] = useState(true);

  const order = persistedPrimaryOrder?.order || null;
  const { isSplitOrder, isLoading: isLoadingSplitOrder } = useGetSplitOrder(order?.lineItems);

  const isTwoOrders = Boolean(persistedPrimaryOrder) && Boolean(persistedSecondaryOrder);

  useEffect(() => {
    setTimeout(() => {
      setHideSplitOrder(false);
    }, LOADER_DURATION);
  });

  const [isLoadingSkeleton, setIsLoadingSkeleton] = useState(true);

  // Simulate Loading State For Skeleton
  useEffect(() => {
    setTimeout(() => {
      setIsLoadingSkeleton(false);
    }, LOADING_STATE_DURATION);
  }, []);

  const isMobile = useBreakpointValue({ base: true, xl: false });

  const companyName = getCompanyName();

  const itemsQuantity = order?.lineItems?.reduce((acc, item) => acc + item.count, 0) ?? 0;

  const orderPlacedDate = order?.createdAt;
  const scheduledDelivery = order?.custom?.fields?.requestDate || '';

  const hasCreditOnHold = hasCreditOnHoldInfo(activeAccount);

  const { total } = getCartSummaryAmounts(order);

  const shouldShowSplitOrderMessage =
    isTwoOrders || (!isLoadingSplitOrder && isSplitOrder && !hideSplitOrder && isTwoOrders);

  const isPickUpDeliver = KEY_NAME_PICK_UP === order?.shippingInfo?.shippingMethodName;

  // When a user types the direct url /checkout/success and there is no order
  // a Extension Exception happens and the application behaves a little weird
  // as this is not considered a expected behavior if the error happens we force
  // logout the user.
  useEffect(() => {
    if (!isCartLoading && !order?.orderNumber) {
      toast({
        status: 'error',
        title: formatMessage({ id: 'checkout.success.orderNotFoundError' }),
        duration: 5000,
        icon: TOAST_ICON.error,
      });
      router.push(routes.HOME);
    }
  }, [order, isCartLoading]);

  useEffect(() => {
    if (!isCartLoading && order) {
      analyticsTrackPurchase({ order });
    }
  }, [isCartLoading, order]);

  if (isCartLoading || isLoadingUserContext) {
    return (
      <Center minHeight={96}>
        <Spinner />
      </Center>
    );
  }

  if (hasCreditOnHold || !canSubmitOrders || !order || !order?.orderNumber) {
    // TODO: show error message
    return null;
  }

  return (
    <ContentContainer>
      <Flex>
        <Box width={{ base: '100%' }}>
          {/* Header Text */}
          <Box pt={{ base: '36px', xl: '64px' }} pb={{ base: '20px', xl: '40px' }}>
            <CustomSkeleton isLoaded={!isLoadingSkeleton} borderRadius={8}>
              <Text color="white" fontWeight="bold" as="h2" fontSize={{ base: 'xl', xl: '44px' }}>
                {formatMessage({ id: 'orderSuccess.text.header' })}
              </Text>
            </CustomSkeleton>
          </Box>

          {/* Order Number, etc... */}
          <Flex gap={{ base: '0px', sm: '20px' }} flexDir={{ base: 'column', sm: 'row' }}>
            <Box paddingRight={{ base: '0px', xl: '64px' }}>
              <CustomSkeleton isLoaded={!isLoadingSkeleton} borderRadius={8}>
                <Text mb="8px" fontWeight="bold" color="white" as="h3" fontSize={{ base: 'base', xl: 'xl' }}>
                  {formatMessage({
                    id: 'orderSuccess.text.confirmation',
                  })}
                  <Text as="span" textTransform="capitalize">
                    {companyName.toLocaleLowerCase()}
                  </Text>
                </Text>
              </CustomSkeleton>
              <CustomSkeleton isLoaded={!isLoadingSkeleton} borderRadius={8}>
                <Text as="p" color="neutral.100" fontSize={{ base: 'sm', xl: 'base' }}>
                  {formatMessage({ id: 'orderSuccess.text.subHeader' })}
                </Text>
              </CustomSkeleton>

              {/* Split Order Text */}
              <Box paddingTop="20px" paddingBottom={{ base: '20px', xl: '40px' }}>
                <CustomSkeleton isLoaded={!isLoadingSkeleton} borderRadius={8}>
                  <Flex alignItems="flex-start" display={shouldShowSplitOrderMessage ? 'flex' : 'none'}>
                    <Image
                      src="/images/WarningDiamond.svg"
                      mr="8px"
                      alt={formatMessage({ id: 'orderSuccess.text.splitOrder' })}
                    />
                    <Text color="yellow.200" as="p" fontSize={{ base: 'xs', xl: 'sm' }} whiteSpace="nowrap">
                      {formatMessage({ id: 'orderSuccess.text.splitOrder' })}
                    </Text>
                  </Flex>
                </CustomSkeleton>
              </Box>
            </Box>

            {/* Return Home btn */}
            <Box pb={{ base: '10', xl: '0' }} marginLeft={{ base: '0', xl: 'auto' }}>
              <CustomSkeleton isLoaded={!isLoadingSkeleton} borderRadius={8}>
                <Link as={NextLink} href="/">
                  <Button
                    as="a"
                    href="/"
                    cursor="pointer"
                    w="fit-content"
                    variant="ds-ghost"
                    bgColor="#2A2B30"
                    size={isMobile ? 'ds-md' : 'ds-lg'}
                    fontSize={{ base: 'xs', xl: 'sm' }}
                    leftIcon={<Icon as={ArrowLeft} size={isMobile ? 16 : 20} />}
                  >
                    {formatMessage({ id: 'orderSuccess.button.returnHome' })}
                  </Button>
                </Link>
              </CustomSkeleton>
            </Box>
          </Flex>

          <Flex flexDir={{ base: 'column', md: 'row' }} gap="20px" pb={{ base: 5, md: 20, xl: '100px' }}>
            {/* Order Details */}

            <Flex flexDir="column" gap="8px" minW={{ base: '100%', md: '295px' }}>
              {!isTwoOrders && (
                <>
                  <CustomSkeleton isLoaded={!isLoadingSkeleton} borderRadius={8}>
                    <BasicPanel title={formatMessage({ id: 'orderSuccess.panel.orderDetails' })}>
                      <TextBlock
                        label={formatMessage({ id: 'orderSuccess.textBlock.poNumber' })}
                        value={order.purchaseOrderNumber || '-'}
                      />
                      <TextBlock
                        label={formatMessage({ id: 'orderSuccess.textBlock.type' })}
                        value={formatMessage({
                          id: getShippingMethodTranslationKey(order?.shippingInfo?.shippingMethodName),
                        })}
                      />
                      <TextBlock
                        label={formatMessage({ id: 'orderSuccess.textBlock.placed' })}
                        value={formatDeliveryDate(orderPlacedDate)}
                      />
                      <TextBlock
                        label={formatMessage({
                          id: isPickUpDeliver ? 'orderSuccess.panel.pickup.date' : 'orderSuccess.textBlock.scheduled',
                        })}
                        value={formatDeliveryDate(scheduledDelivery)}
                      />
                      <TextBlock
                        label={formatMessage({ id: 'orderSuccess.textBlock.instructions' })}
                        value={order.custom.fields.specialHandlingInstructions || '-'}
                        isStack
                      />
                    </BasicPanel>
                  </CustomSkeleton>

                  {/* Deliver to */}
                  <CustomSkeleton isLoaded={!isLoadingSkeleton} borderRadius={8}>
                    <BasicPanel
                      title={formatMessage({
                        id: isPickUpDeliver ? 'orderDetails.pickUpAt' : 'orderSuccess.panel.deliverTo',
                      })}
                    >
                      <DeliveryTo warehouseAddress={isPickUpDeliver ? warehouseAddress : null} />
                    </BasicPanel>
                  </CustomSkeleton>
                </>
              )}

              {/* Bill to */}
              <CustomSkeleton isLoaded={!isLoadingSkeleton} borderRadius={8}>
                <BasicPanel title={formatMessage({ id: 'orderSuccess.panel.billTo' })}>
                  <BillTo successPage />
                </BasicPanel>
              </CustomSkeleton>
            </Flex>

            {/* Order Summary */}
            <Box width="full" pb={{ base: '64px', xl: '0px' }}>
              {isTwoOrders ? (
                /* When handling a split order, ensure that items with lead days are displayed second in the checkout page.
                The order with lead days (Secondary Split Order container) is displayed after the order without lead days (Primary Split Order Container). */

                <CustomSkeleton isLoaded={!isLoadingSkeleton} borderRadius={8}>
                  <VStack align="unset" width="full" gap={{ base: 8, md: 10 }}>
                    <SplitOrderSummary
                      order={persistedPrimaryOrder.order}
                      timeWindow={persistedPrimaryOrder.selectedWindowDelivery}
                      warehouseAddress={persistedPrimaryOrder.warehouseAddress}
                      label="principalOrder"
                    />
                    <SplitOrderSummary
                      order={persistedSecondaryOrder.order}
                      timeWindow={persistedSecondaryOrder.selectedWindowDelivery}
                      warehouseAddress={persistedSecondaryOrder.warehouseAddress}
                      label="secondaryOrder"
                    />
                  </VStack>
                </CustomSkeleton>
              ) : (
                <CustomSkeleton isLoaded={!isLoadingSkeleton} borderRadius={8}>
                  <OrderSummary
                    isAlwaysOpen
                    orderTotal={total}
                    cases={itemsQuantity}
                    items={order?.lineItems?.length}
                    lineItems={order?.lineItems}
                  />
                </CustomSkeleton>
              )}
            </Box>
          </Flex>
        </Box>
      </Flex>
    </ContentContainer>
  );
};
