import { Button, Flex, Icon, Text } from '@chakra-ui/react';
import { useFormat } from 'helpers/hooks';
import { ArrowSquareOut } from 'phosphor-react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { getOrderDetailsFromRoute } from './helpers/get-order-details-from-route';
import { analyticsTrackPrintBackDownload } from 'composable/analytics/analytics-event-tracking';

export const ViewPrintBackButton = () => {
  const { formatMessage } = useFormat({ name: 'common' });
  const router = useRouter();
  const { customerNumber, orderNumber } = getOrderDetailsFromRoute(router);

  return (
    <NextLink
      href={`/order/${customerNumber}/${orderNumber}/printback`}
      passHref
      target="_blank"
      rel="noopener noreferrer"
    >
      <Button
        as="a"
        size="ds-md"
        mx="8"
        bgColor="#fff"
        variant="ds-ghost-on-light"
        isDisabled={false}
        target="_blank"
        rel="noopener noreferrer"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Flex justifyContent="center" flex="1">
          <Text as="p" transform="translateX(10px)">
            {formatMessage({ id: 'button.viewPrintback' })}
          </Text>
        </Flex>
        <Icon as={ArrowSquareOut} />
      </Button>
    </NextLink>
  );
};
