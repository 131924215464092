import { useState, useEffect } from 'react';
import { useBreakpointValue } from '@chakra-ui/react';

export const useKeyboardVisibility = () => {
  const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);
  const [keyboardHeight, setKeyboardHeight] = useState(0);
  const isMobile = useBreakpointValue({ base: true, xl: false });

  useEffect(() => {
    if (!isMobile || !window.visualViewport) return;

    const detectKeyboard = () => {
      const isOpen = window.visualViewport.height < window.innerHeight;
      setIsKeyboardVisible(isOpen);

      if (isOpen) {
        const keyboardSpace = window.innerHeight - window.visualViewport.height;
        setKeyboardHeight(Math.max(keyboardSpace - 1, 0));
      } else {
        setKeyboardHeight(0);
      }
    };

    const handleFocus = () => setIsKeyboardVisible(true);
    const handleBlur = () => setIsKeyboardVisible(false);

    window.visualViewport.addEventListener('resize', detectKeyboard);
    window.visualViewport.addEventListener('scroll', detectKeyboard);

    window.addEventListener('focusin', handleFocus);
    window.addEventListener('focusout', handleBlur);

    return () => {
      window.visualViewport.removeEventListener('resize', detectKeyboard);
      window.visualViewport.removeEventListener('scroll', detectKeyboard);
      window.removeEventListener('focusin', handleFocus);
      window.removeEventListener('focusout', handleBlur);
    };
  }, [isMobile]);

  return { isKeyboardVisible, keyboardHeight };
};
