import { LOGROCKET_ID } from 'composable/analytics/logrocket/constants';

declare global {
  interface Window {
    LogRocket: any;
  }
}

type TrackLoginPageParams = {
  mdGetAzureUrls?: number;
  mdGetTokens?: number;
  mdLogin?: number;
};

export const trackLoginPage = async ({ mdGetAzureUrls, mdLogin, mdGetTokens }: TrackLoginPageParams) => {
  const totalTime = mdGetAzureUrls + mdGetTokens + mdLogin;

  if (LOGROCKET_ID !== null && typeof window !== 'undefined' && window.LogRocket) {
    const payload = {
      mdGetAzureUrls: typeof mdGetAzureUrls === 'number' ? mdGetAzureUrls : 0,
      mdGetTokens: typeof mdGetTokens === 'number' ? mdGetTokens : 0,
      mdLogin: typeof mdLogin === 'number' ? mdLogin : 0,
      feWork: typeof mdLogin === 'number' ? mdLogin : 0,
      totalTime: typeof totalTime === 'number' ? totalTime : 0,
    };
    window.LogRocket?.track('Login', payload);
  }
};
