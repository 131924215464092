import { Dispatch, SetStateAction } from 'react';
import { AsAssociateBusinessUnitResult } from '@Types/business-unit/BusinessUnit';
import { Customer } from '@Types/customer/Customer';
import { ShamrockUser } from 'frontastic/actions/shamrockApi/types';

export type UserState = {
  accessToken?: string;
  refreshToken?: string;
  expiresOn?: number;
  ctUser?: CustomerProps;
  shamrockUser?: ShamrockUser;
  activeAccount?: Partial<AsAssociateBusinessUnitResult>;
  activeWarehouse?: WarehouseProps;
  accountList?: AsAssociateBusinessUnitResult[];
  loading: boolean;
  isSuperUser?: boolean;
  loggedAsSuperUser?: boolean;
  superUserEmail?: string;
  readyToLogin?: boolean;
};

export enum UserGlobalStateActions {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  SET_LOADING = 'SET_LOADING',
  SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN',
  SET_IS_SUPER_USER = 'SET_IS_SUPER_USER',
  SET_LOGGED_AS_SUPER_USER = 'SET_LOGGED_AS_SUPER_USER',
  SET_ACCOUNT_LIST = 'SET_ACCOUNT_LIST',
  SET_READY_TO_LOGIN = 'SET_READY_TO_LOGIN',
  SET_STATE_FOR_NEW_IMPERSONATION = 'SET_STATE_FOR_NEW_IMPERSONATION',
}

export type UserGlobalAction = {
  type: UserGlobalStateActions;
  payload?: Partial<UserState>;
};

export interface UseUserGlobalResponse {
  state: UserState;
  dispatch: Dispatch<UserGlobalAction>;
  logout: (hardLogout?: boolean, message?: string, caller?: string) => Promise<void>;
  switchAccount: (accountKey: string) => Promise<void>;
  impersonateUser: (accessToken: string, accountKey: string, impersonatedUserId: string) => Promise<void>;
  canViewProductCatalog: () => boolean;
  azureRedirectUrl: string;
  fetchAllAccountsWithCarts: () => Promise<AsAssociateBusinessUnitResult[]>;
}

export interface CustomerProps {
  customer: Partial<Customer> | null;
  businessUnits: Partial<AsAssociateBusinessUnitResult[]> | [];
}

export interface WarehouseProps {
  payBillUrl: string;
  businessUnit: string;
  businessSegment: string;
  warehouseNumber: string;
  id?: string;
}
export interface CustomerDataProps {
  accessToken: string;
  userId: string;
  customer: CustomerProps | null;
  customerPermissionsFromShamrock: string[];
}

export interface UseUserReturn {
  isLoadingUserData: boolean;
  loggedIn: boolean;
  accessToken: string;
  user: Partial<Customer> | null;
  customerPermissionsFromShamrock: string[];
  activeWarehouse: WarehouseProps;
  warehouseList: WarehouseProps[];
  accountList: Partial<AsAssociateBusinessUnitResult[]>;
  listOfAccountNumbers: string[];
  activeAccount: Partial<AsAssociateBusinessUnitResult> | null;
  canViewProductCatalog: () => boolean;
  setActiveAccountKey: (key: string) => void;
  login: (accessToken?: string) => Promise<void>;
  logout: (hardLogout?: boolean, errorCode?: string) => Promise<void>;
  switchAccount: (accountKey: string) => void;
  setIsUserSwitchingAccount: React.Dispatch<React.SetStateAction<boolean>>;
  azureRedirectUrl: string;
  cartId?: string;
  setCartId: Dispatch<SetStateAction<string>>;
}

export type LogLoginType = {
  tokens: {
    access: string;
    refresh: string;
  };
  url: string;
  azureUrl?: string;
  redirectedFromShamrockOrders?: boolean;
  noCodeQueryParamNoAccessToken?: boolean;
  sessionExpired?: boolean;
  hasValidToken?: boolean;
  canIGetTokens?: boolean;
  responseFromBrowserAccessCall?: {
    accessToken?: string;
    refreshToken?: string;
    expiresOn?: number;
    origin?: string;
    success?: boolean;
  };
  shouldTrackLogin?: boolean;
  refreshToken?: {
    attempt?: boolean;
    success?: boolean;
  };
  unableToLogin?: boolean;
  loginCall?: {
    buLength?: number;
    isSuperUser?: boolean;
    frontasticRequestid?;
    shamrockUserId?;
  };
};

export type LogLogoutType = {
  hardLogout: boolean;
  message: string;
  originPath: string;
  caller: string | object;
  trace: string[];
  refresh?: {
    attempt?: boolean;
    newToken?: string;
    success?: boolean;
  };
  hardLogoutCall?: {
    attempt?: boolean;
    success?: boolean;
    thereIsFrontasticCookie?: boolean;
    azureLogout?: boolean;
    error?: string;
  };
  softLogout?: boolean;
};
