import { OrderDetail } from '@Types/shamrockApi/Order';
import { ACCESS_TOKEN } from 'composable/helpers/constants';
import { format } from 'date-fns/format';
import { UpdateOrderApiParams } from 'frontastic/actions/shamrockApi/types';

export const mountUpdateOrderPayload: (order: OrderDetail) => UpdateOrderApiParams = (order) => {
  const editedLineItems = order.lineItems.map((item) => ({
    productNumber: item.productNumber,
    quantity: item.orderedQuantity,
  }));

  const removedLineItems = (order.removedSkus ?? []).map((sku) => ({
    productNumber: sku,
    quantity: 0,
  }));

  const lineItems = [...editedLineItems, ...removedLineItems];
  // Parse access token as it's sotored using JSON.stringify
  let accessToken = window.localStorage.getItem(ACCESS_TOKEN);
  if (accessToken != null && accessToken !== '') {
    try {
      accessToken = JSON.parse(accessToken);
    } catch {
      console.log('Failed to parse accessToken');
    }
  }

  let requestDate = '';
  try {
    requestDate = order?.requestDate ? format(order.requestDate, 'yyyy-MM-dd') : '';
  } catch {
    console.error('Failed to parse requestDate: ', order?.requestDate);
  }

  return {
    // since useEditOrderMode is called in useGlobal().useUserGlobal, we can't use the accessToken from there
    accessToken,
    warehouseNumber: order.warehouseNumber,
    businessUnitName: order.businessUnitName,
    businessSegmentName: order.businessSegmentName,
    customerNumber: order.customerNumber,
    sourceSystemRequestNumber: order.orderNumber,
    nodeId: 0,
    existingSalesOrderNumber: order.orderNumber,
    orderType: order.orderType,
    requestDate: requestDate,
    transactionSourceSystem: 'EcommerceDesktop',
    customerPurchaseOrderNumber: order.purchaseOrderNumber,
    handlingInstructions: order.handlingInstructions,
    deliveryMinimumConfirmed: true,
    justInTimeShipUponReceiptConfirmed: true,
    lineItems,
    geocode: order.geocode ?? '',
  };
};
